.logoImage{
    margin-top: 1%;
    margin-right: 1rem;
    margin-left: 3%;
    width: 4rem;
}

.nav{
    -webkit-transition: transform 1s ease-in-out;
    -moz-transition: transform 1s ease-in-out;
    -ms-transition: transform 1s ease-in-out;
    -o-transition: transform 1s ease-in-out;
    transition-delay: 0.5s;
}

.navhide{
    /* opacity: 0; */
    transform: translateY(-100px);
}

.footer{
    padding-top: 8%;
    height: 35rem;
}

.footerIcon{
    margin: 1rem;
}

.copyright{
    font-size: 0.8rem;
}

.btn{
    outline: none !important;
    box-shadow: none !important;
}