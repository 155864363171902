@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@1&family=Sacramento&display=swap');
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}  

.backgroundcolor1{
    background-color: #232323 !important;
    color: #99A8B2;
}
.backgroundcolor2{
    background-color: red !important;
    color: #99A8B2;
}
.textColor1{
    color: #99A8B2 !important;
}
.textColor2{
    color: #D2FAFB;
}
.textColor3{
    color: #232323 !important;;
}
.textColor4{
    color: #ffffff !important;;
}
.miamiBlue{
    color: #00B5C8 !important;
}
.bg-miamiBlue{
    background-color: #00B5C8 !important;
}
.SacramentoFont{
    font-family: 'Sacramento', cursive;
}
.PoppinsFont{
    font-family: 'Poppins', sans-serif;
}

a{
    text-decoration: none !important;
}

.linkHover {
    color: #99A8B2;
    text-decoration: none; /* Optional: Removes the underline */
}

.linkHover:hover {
    color: #00B5C8; /* Replace with the color you want on hover */
}

.linkHover3 {
    color: #232323;
    text-decoration: none; /* Optional: Removes the underline */
}

.linkHover3:hover {
    color: #00B5C8; /* Replace with the color you want on hover */
}