.body {
  /* text-align: center; */
}

.firstContainer{
  height: 100vh;
  min-height: 320px;
}

.titleContainer{

}

.title{
  margin-bottom: 8vh;
}

.profileContainer{
  /* height: 90vh;
  min-height: 800px; */
  margin: 10vh;
}

.profileImage{
  width: 80%;
  margin: 10%;
}

.programmerContainer{
  /* min-height: 1200px;
  height: 100vh; */

}

.programmerTitle{
}

.programmerLabel{
  /* text-align: right; */
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.progress{
  height: 4px !important;
  width: 65%;
}

.icon{
  margin: 1rem;
}

.creatorContainer{
  /* min-height: 50vh;
  height: 100vh;  */
}

.creatorTitle{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.githubButtontext {
  color: #232323;
  text-decoration: none; /* Optional: Removes the underline */
}

.githubButtontext:hover {
  color: #00B5C8; /* Replace with the color you want on hover */
}